<template>
  <el-dialog
    title="新建合同模板"
    width="900px"
    @close="close('ruleForm')"
    :visible="dialogVisible"
  >
    <el-form
      label-position="top"
      width="900px"
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="ruleForm"
      label-width="80px"
    >
      <el-form-item label="编码" prop="code">
        <el-input v-model="ruleForm.code"></el-input>
      </el-form-item>

      <el-form-item label="名称" prop="name">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.name"
        ></el-input>
      </el-form-item>

      <el-form-item label="描述" prop="desc">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.desc"
        ></el-input>
      </el-form-item>

      <el-form-item label="内容" prop="content">
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="ruleForm.content"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import { api } from '/src/api/base';
//新增接口
export const ContractTemplateAdd = api('/contractTemplate')(
  'contractTemplate.add.json'
);
//查询列表接口
export const ContractTemplateList = api('/contractTemplate')(
  'contractTemplate.list.json'
);
//查询详情接口
export const ContractTemplateDetail = api('/contractTemplate')(
  'contractTemplate.describe.json'
);
//编辑接口
export const ContractTemplateEdit = api('/contractTemplate')(
  'contractTemplate.edit.json'
);
//删除接口
export const ContractTemplateDel = api('/contractTemplate')(
  'contractTemplate.del.json'
);
export default {
  components: {
    //Upload: () => import("/src/components/upload.vue")
  },
  data () {
    return {
      ruleForm: {
        code: '',

        name: '',

        desc: '',

        content: ''
      },
      rules: {
        code: [],

        name: [],

        desc: [],

        content: []
      }
    };
  },
  props: {
    dialogVisible: Boolean
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          alert('submit!');
          ContractTemplateAdd(this.ruleForm).then(res => {
            console.log(res);
            this.closeDialog();
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    close (formName) {
      this.$refs[formName].resetFields();
      this.closeDialog();
    },
    closeDialog () {
      this.$emit('update:dialogVisible', false);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}

.el-form-item__label {
  padding-bottom: 0 !important;
}
</style>
